import qs from "qs"
import {
    DataJson,
    SubtitlePostItem,
    DataJsonItem,
    ImdbTopItems,
} from "../typing"
import { downloadSubtitle } from "./downloader"
import { containsNonLatinCodepoints, encodeEscapeJson } from "./util"
import siteConfig from "../constants/site-config.json"

export async function getLatestAdd(filters?: any, pageSize = 10, page = 1) {
    try {
        const path = qs.stringify(
            {
                filters,
                sort: ["id:desc"],
                pagination: {
                    page,
                    pageSize,
                },
                fields: [
                    "title",
                    "permalink",
                    "poster",
                    "year",
                    "genre",
                    "country",
                    "createdAt",
                ],
            },
            {
                encodeValuesOnly: true,
            },
        )
        const api = await fetch(`${process.env.API_URL}/api/titles?${path}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + process.env.API_TOKEN,
            },
        })
        const res = await api.json()
        res.data.map((item: SubtitlePostItem) => {
            item.attributes.poster =
                item.attributes.poster == "N/A"
                    ? siteConfig.site_no_image
                    : image_cdn(item.attributes.poster)
            const dateUpdate = new Date(item.attributes.createdAt).getTime()
            const dateNow = new Date().getTime()
            const diff = dateNow - dateUpdate
            item.attributes.newSubtitle = diff < 259200000 ? true : false
            return item
            // item.attributes.title = cleanTitle(item.attributes.title)
        })
        return res
    } catch (error) {
        console.log(error)
    }
}

export async function getLatestAddSort(
    filters?: any,
    pageSize = 10,
    page = 1,
    sort = ["id:desc"],
) {
    try {
        const path = qs.stringify(
            {
                filters,
                sort,
                pagination: {
                    page,
                    pageSize,
                },
                fields: [
                    "title",
                    "permalink",
                    "poster",
                    "year",
                    "genre",
                    "country",
                    "createdAt",
                ],
            },
            {
                encodeValuesOnly: true,
            },
        )
        const api = await fetch(`${process.env.API_URL}/api/titles?${path}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + process.env.API_TOKEN,
            },
        })
        const res = await api.json()
        res.data.map((item: SubtitlePostItem) => {
            item.attributes.poster =
                item.attributes.poster == "N/A"
                    ? siteConfig.site_no_image
                    : image_cdn(item.attributes.poster)
            const dateUpdate = new Date(item.attributes.createdAt).getTime()
            const dateNow = new Date().getTime()
            const diff = dateNow - dateUpdate
            item.attributes.newSubtitle = diff < 259200000 ? true : false
            return item
            // item.attributes.title = cleanTitle(item.attributes.title)
        })
        return res
    } catch (error) {
        console.log(error)
    }
}

export async function getSubtitleById(id: number) {
    try {
        const res = await reqAPI(`titles/${id}`)
        if (!res.data.id) throw "Subtitle not found"
        return res
    } catch (error) {}
}

export async function getSubtitlesByPermalink(permalink: string) {
    try {
        const api = await fetch(
            `${process.env.API_URL}/api/titles?filters[permalink][$eq]=${permalink}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + process.env.API_TOKEN,
                },
            },
        )
        const res = await api.json()
        if (res.data.length < 1) throw "permalink not found"
        return res.data[0]
    } catch (error) {
        console.log(error)
    }
}

export async function getSubtitleImdbJson(permalink: string) {
    try {
        const api = await fetch(
            `${process.env.API_URL}/api/titles?filters[permalink][$eq]=${permalink}&fields[0]=imdb`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + process.env.API_TOKEN,
                },
            },
        )
        const res = await api.json()
        // console.log(res.data[0].attributes.imdb?.Title)
        if (res?.data.length < 1 || !res.data[0].attributes.imdb?.Title)
            throw "subtitle imdb not found"
        return res.data[0]
    } catch (error) {
        console.log("subtitle imdb not found")
    }
}

export async function getSubtitleImdbSs(imdbId: string) {
    try {
        const api = await fetch(
            `${process.env.API_URL}/api/titles?filters[imdb_id][$eq]=${imdbId}&filters[permalink][$contains]=ss-&fields[0]=imdb&fields[1]=permalink`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + process.env.API_TOKEN,
                },
            },
        )
        const res = await api.json()
        // console.log(res.data[0].attributes.imdb?.Title)
        if (res?.data.length < 1 || !res.data[0].attributes.imdb?.Title)
            throw "subtitle imdb not found"
        return res.data[0]
    } catch (error) {
        console.log("subtitle imdb not found")
    }
}

export async function getSubtitleView(permalink: string) {
    try {
        const api = await fetch(
            `${process.env.API_URL}/api/titles?filters[permalink][$eq]=${permalink}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + process.env.API_TOKEN,
                },
            },
        )
        const res = await api.json()
        if (res.data.length < 1) throw "permalink not found"
        return res.data[0]
    } catch (error) {
        console.log(error)
    }
}

export async function updateSubtitleData(id: number, data: any) {
    try {
        // console.log(id, data)
        // console.log(data)
        const api = await fetch(`${process.env.API_URL}/api/titles/${id}`, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + process.env.API_TOKEN,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ data }),
        })
        const res = await api.json()
        // console.log(res)
        if (!res?.data?.id) throw `Failed update subtitle id:${id}`
        return res
    } catch (error) {
        console.log(error)
    }
}

export async function updateSubtitle(permalink: string, id: number) {
    try {
        const reqsubscene = await fetch(
            `${process.env.API_NODE}/subtitles/${permalink}?lang=indonesian`,
        )
        const subscene = await reqsubscene.json()
        if (subscene?.title && subscene?.id) {
            subscene.items.map((item: DataJsonItem) => {
                item.subtitle_title = encodeEscapeJson(item.subtitle_title)
                return item
            })
            const updateData = await updateSubtitleData(id, {
                datajson: subscene,
                date: new Date().toISOString(),
            })
            if (updateData?.data?.id) {
                // await revalidateSubtitle(permalink)
                updateData?.data.attributes.datajson.items.map(
                    (item: DataJsonItem) => {
                        item.subtitle_title = decodeURIComponent(
                            item.subtitle_title,
                        )
                        return item
                    },
                )
                return updateData
            }
        }
        // const imdb_id = await findImdb(subscene?.imdb_id, subscene.title)
        // const imdb = await getImdb(imdb_id)
    } catch (error) {
        console.log(error)
    }
}

export async function revalidateSubtitle(permalink: string) {
    try {
        const req = await fetch(`${process.env.BASE_URL}/api/update`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + process.env.API_TOKEN,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: process.env.REVALIDATE_TOKEN,
                page: `/subtitle-${permalink}`,
            }),
        })
        const res = await req.json()
        return res
    } catch (error) {}
}

export async function findImdb(id: any, title: string) {
    try {
        if (id) {
            const imdb_id = id.length < 9 ? id.replace("tt", "tt0") : id
            return imdb_id
        }
        const reqimdb = await fetch(
            `${process.env.API_NODE}/imdb?s=${encodeURI(cleanTitle(title))}`,
        )
        const findimdb = await reqimdb.json()
        if (findimdb.data.Search) {
            return findimdb.data.Search[0].imdbID
        }
    } catch (error) {}
}

export async function getImdb(id: string) {
    try {
        const req = await fetch(`${process.env.API_NODE}/imdb?id=${id}`)
        const res = await req.json()
        if (res.ok !== true) throw "Not true"
        return res.data
    } catch (error) {}
}

export async function addSubtitleToDB(result: any) {
    try {
        // data.datajson = JSON.stringify(data.datajson)
        // data.imdb = JSON.stringify(data?.imdb)
        const postdata = {
            data: result,
        }
        const api = await fetch(`${process.env.API_URL}/api/titles`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + process.env.API_TOKEN,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postdata),
        })
        const res = await api.json()
        return res
    } catch (error) {
        console.log(error)
    }
}

export async function addDataToDB(db: string, result: any) {
    try {
        const postdata = {
            data: result,
        }
        const api = await fetch(`${process.env.API_URL}/api/${db}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + process.env.API_TOKEN,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postdata),
        })
        const res = await api.json()
        return res
    } catch (error) {
        console.log(error)
    }
}

export async function addSubtitleDownloadDB(result: any) {
    try {
        // console.log(result.datajson.info)
        result.datajson.info = encodeEscapeJson(result.datajson.info)
        // console.log(result.datajson)
        const postdata = {
            data: result,
        }
        const api = await fetch(`${process.env.API_URL}/api/subtitles`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + process.env.API_TOKEN,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postdata),
        })
        const res = await api.json()
    } catch (error) {
        console.log(error)
    }
}

export async function setSubtitleDownload(
    permalink: string,
    lang: string,
    subtitle_id: string,
) {
    try {
        const query = qs.stringify(
            {
                filters: {
                    $and: [
                        {
                            permalink_post: {
                                $eq: permalink,
                            },
                        },
                        {
                            subtitle_id: {
                                $eq: subtitle_id,
                            },
                        },
                    ],
                },
            },
            {
                encodeValuesOnly: true, // prettify URL
            },
        )
        const api = await fetch(
            `${process.env.API_URL}/api/subtitles?${query}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + process.env.API_TOKEN,
                },
            },
        )
        const res = await api.json()
        if (res?.data?.length < 1) {
            const data = await downloadSubtitle(permalink, subtitle_id, lang)
            const result = {
                permalink_post: permalink,
                subtitle_id,
                subtitle_name: data.name,
                datajson: data,
            }
            // console.log(data)
            if (data?.items?.length >= 1) {
                const addDB = await addSubtitleDownloadDB(result)
            }
            data.info = decodeURIComponent(data.info)
            // console.log(data)
            return { load: false, data }
        }
        // console.log(decodeURIComponent(res.data[0].attributes.datajson))
        const datalama = res.data[0].attributes.datajson
        datalama.info = decodeURIComponent(datalama.info)
        return {
            load: true,
            data: datalama,
        }
    } catch (error) {
        console.log(error)
    }
}

export async function setSubtitleSubdl(
    permalink: string,
    lang: string,
    sId: string,
) {
    try {
        const checker = await reqAPI(`subs?filters[sid][$eq]=${sId}`)
        const req = await fetch(
            `${process.env.API_NODE}/dlsc/${permalink}/${sId}`,
        )
        const res = await req.json()
        const datalama = checker.data[0].attributes.datajson
        datalama.items = res.items
        return {
            load: true,
            data: datalama,
        }
    } catch (error) {
        console.log(error)
    }
}

export async function getImdbTop() {
    try {
        const req = await fetch(`${process.env.API_NODE}/top`)
        const res = await req.json()
        if (!res?.items) throw "no response imdbtop"
        res.items.map((item: ImdbTopItems) => {
            item.poster = image_cdn(item.poster)
            return item
        })
        return res
    } catch (error) {
        console.log(error)
        // return false
    }
}

export function image_cdn(src: string) {
    if (src.includes("media-amazon.com")) {
        const cdnSrc = src
            .replace(
                "m.media-amazon.com/images/M/",
                `i2.wp.com/${process.env.IMAGE_CDN}/img-m/`,
            )
            .replace("_SX300", "")
        return cdnSrc
    } else {
        const cdnSrc = src
            .replace(
                "i.jeded.com/i/",
                `i2.wp.com/${process.env.IMAGE_CDN}/poster/`,
            )
            .replace("154-", "")
            .replace("135-", "")
        return cdnSrc
    }
}

export function cleanTitle(title: string) {
    if (title.includes("(")) {
        const dalamKurung = title.replace(/ *\([^)]*\) */g, " ")
        return dalamKurung.trim()
    }
    return title
}

export function checkNewSubtitle(date: string) {
    const dateUpdate = new Date(date).getTime()
    const dateNow = new Date().getTime()
    const diff = dateNow - dateUpdate
    return diff < 259200000 ? true : false
}

export async function reqAPI(query: string) {
    try {
        const api = await fetch(`${process.env.API_URL}/api/${query}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + process.env.API_TOKEN,
            },
        })
        const res = await api.json()
        return res
    } catch (error) {
        console.log(error)
    }
}

export async function getDrakorinId(imdb_id: string) {
    try {
        const token =
            "11ee552e558a6788828b192db068d58d10fabb1ffb3ba9d4148bb27b5c054a7737e52656b20cf8f66544ef7bb1d262c311dff4a4ac2a05e8473becec131905136564d7322621b70f10a8c5ab8a0079bdf989cea24c207e1355a9c05d1ecb8a6c0cb445bd2fa21018ea28576ea08ec33f2d5515e87a433e2d2628752f9e2cf666"
        const api = await fetch(
            `https://api-strapi.xjavhd.com/api/dramas?filters[slug][$eq]=${imdb_id}&fields[0]=title`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
        )
        return api.json()
    } catch (error) {
        console.log(error)
    }
}
